import React from "react"
import "./themeMenu.scss"

const ThemeMenu = ({svgIcon, alt}) => (
  <div id="theme-icon" onClick={toggleTheme}/>
)

const toggleTheme = () => {
  let theme = document.documentElement.getAttribute('data-theme');
  let newTheme = theme === 'dark' ? 'light' : 'dark';
  document.documentElement.setAttribute('data-theme', newTheme);

  document.getElementById('theme-icon').setAttribute('data-theme', newTheme);

  localStorage.setItem('data-theme', newTheme);
};

export default ThemeMenu