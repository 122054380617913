import PropTypes from "prop-types"
import React from "react"
import "../styles/main.scss"
import "./header.scss"
import Logo from "../images/svg/mychrizLogo.svg"
import Nav from '../components/nav'
import ThemeToggle from "./themeMenu"
import themeDark from "../images/svg/theme-dark.svg"
//import themeLight from "../images/svg/theme-light.svg"
import { Link } from "gatsby"


const Header = ({ siteTitle }) => (
  <header>
    <Link to="/" className="back-home">
      <img src={Logo} alt="mychriz logo" className="brand-logo"/>
    </Link>
    <Nav />
    <ThemeToggle svgIcon={themeDark} alt={"theme"} id="icon-dark" className="theme-icon"/>
    {/* <ThemeToggle svgIcon={themeLight} alt={"theme"} id="icon-light" className="theme-icon"/> */}
    <h1>{siteTitle}</h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
