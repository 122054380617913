import React from "react"
import "./nav.module.scss"
import NavItem from "../components/navItem"

const Nav = () => (
  <nav>
    <NavItem label="about" link={"/about/"}/>
    <NavItem label="work" link={"/work/"}/>
    <NavItem label="#TIL" link={"/til/"}/>
    <NavItem label="contact" link={"/contact/"}/>
  </nav>
)

export default Nav