import React from "react"
import "../styles/main.scss"
import "./footer.scss"

const Footer = () => (
  <footer>
    <p>
      © {new Date().getFullYear()} mychriz <br/> 
      Build with <a href="https://www.gatsbyjs.com/" rel="noopener noreferrer" target="_blank">Gatsby</a> and 
      hosted on <a href="https://www.netlify.com/" rel="noopener noreferrer" target="_blank">Netlify</a>
    </p>
  </footer>
)

export default Footer