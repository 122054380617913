import React from "react"
import { Link } from "gatsby"
import navItem from"./navItem.module.scss"

const NavItem = ({label, link}) => (
  <Link to={link} className={navItem.navItem}>
    {label}
  </Link>
)

export default NavItem