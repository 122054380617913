/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import appleTouch from "../images/favicon/apple-touch-icon.png";
import favicon16 from "../images/favicon/favicon-16x16.png";
import favicon32 from "../images/favicon/favicon-32x32.png";
import faviconIco from "../images/favicon/favicon.ico";
import favicon150 from "../images/favicon/mstile-150x150.png";
import faviconSVG from "../images/favicon/safari-pinned-tab.svg";




function SEO({ description, lang, meta, keywords, siteTitle }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={siteTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: siteTitle,
        },
        {
          property: `og:locale`,
          content: `de_DE`
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: siteTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      link={[
        { rel: "apple-touch-icon", sizes: "180x180", href: `${appleTouch}` },
        { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
        { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
        { rel: "mask-icon", href: `${faviconSVG}`, color: "#5bbad5"},
        { rel: "shortcut icon", href: `${faviconIco}` },
        { rel: "icon", type: "image/png", sizes: "150x150", href: `${favicon150}` },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  siteTitle: PropTypes.string.isRequired,
}

export default SEO
